import React from "react"
import { Link, PageProps } from "gatsby"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

const IndexPage: React.FC<PageProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Home" />
    <h1>Liam Myles</h1>
    <p>
      This is my little corner on the web, it&apos;s messy, unfinished, but
      it&apos;s mine! 😁
    </p>
    <h2>My Projects</h2>
    <p>
      I have done all sorts of tinkering over the years. You can find most of
      them on my projects page 🥳
    </p>
    <Link className="multiline" to="/projects">
      Explore my projects?
    </Link>
    <h2>My Professional Side</h2>
    <p>It&apos;s just a CV, maybe one day I will make it more interesting</p>

    <Link className="multiline" to="/professional">
      View my CV?
    </Link>
    <h2>Personal Life</h2>
    <p>
      I value openness and honesty, and this is me practising a little bit of
      that.
    </p>
    <Link className="multiline" to="/personal">
      Learn about me?
    </Link>
    <h2>Blog</h2>
    <p>
      I have more half-finished blogs than finished ones, but you will find my
      finished blogs forever home.
    </p>
    <Link className="multiline" to="/blog">
      Read what I wrote?
    </Link>
  </StandardLayout>
)

export default IndexPage
